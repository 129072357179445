.walletView {
  height: 100%;
}

.walletView .puzzle {
}

.puzzleList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
}

.walletTitle {
  margin-bottom: 20px !important
}

.puzzle {
  margin: 0 auto;
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.puzzle.selected {
  background-color: #FFFFFF3A;
  border-radius: 2px;
}

.puzzle p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.puzzle.today p {
  font-weight: bold;
}

.puzzle img, .puzzle canvas {
  margin: 0 auto;
}

.puzzle img {
  margin: 0 auto;
  height: 40px;
  width: 40px;
}

.puzzle.empty {

}

.puzzle.completed {

}
.walletButtons button {
  margin: 5px;
}