* {
  user-select: none;
}

.App {
  text-align: center;
}

.App .title {
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.App header {
  padding: 5px;
}

.App header nav {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.App header nav h1 {
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

.App header nav .button {
  width: 34px;
  height: 34px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.App .mainArea {
  flex-grow: 1;
  display: contents;
}

.App .gameScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.App .gameScreen .resultContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
}

.keyboard {
  min-height: 200px;
  position: relative;
  top: 10px;
}

.App .gameScreen .resultContent button {
  margin: 5px;
  margin-top: 10px;
}

.App .gameScreen .primary {
  flex-grow: 1;
}

.App .gameScreen .gridConstraint {
  margin: 0 auto;
}

#helpScreen {
  max-width: 800px;
  margin: 0 auto;
  font-size: 0.9em;
}

.bannerContainer {
  position: relative;
  height: 24px;
}

.banner {
  display: flex;
  flex-direction: column;;
  align-items: center;
  justify-content: center;
}

.banner {
  height: 0px;
  margin-top: -10px;
  text-align: center;
  overflow: hidden;
}

.banner.info {
  background: rgb(2,0,36);
  background: var(--banner-background-info);
  font-size: var(--banner-font-size-info);
  text-transform: uppercase;
  color: var(--banner-font-color);
}

.banner.reveal {
  background: rgb(2,0,36);
  background: var(--banner-background-reveal);
  font-size: var(--banner-font-size-reveal);
  text-transform: uppercase;
  color: var(--banner-font-color);
  font-weight: bold;
}

#helpScreen .gridConstraint  {
  margin: 0 auto;
}

#helpScreen p {
  margin: 0px;
}

.App .gameScreen .primary {
  margin: 0 10px;
}

#resultScreen .actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#resultScreen .actions button {
  width: 300px;
}

.App .statsView h3, .App .statsView h2 {
  text-transform: uppercase;
}

.App .statsView .metrics {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App .statsView .metrics > div {
  height: 60px;
  margin: 0 10px;
  padding-top: 70px;
  width: 80px;
}

.App .statsView .metrics > div > div:first-child {
  font-size: 0.8em
}

.App .statsView .metrics > div > div:last-child {
  font-size: 1.4em
}

.statsView div.wins {
  background-image: url(./skin/assets/statsCrown.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 80%;
}

.statsView div.streak {
  background-image: url(./skin/assets/statsFire.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 60%;
}

.statsView div.winPerc {
  background-image: url(./skin/assets/statsCalc.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 70%;
}

.App .panel {
  max-width: min(600px, 100% - 20px);
  padding: 10px;
  background-color: var(--panel-bg-color);
  border: var(--panel-border-color) solid 1px;
  border-radius: var(--button-corner-radius);
}

.panel ::-webkit-scrollbar {
  width: 4px;
}

.App .panel .content.scrollable {
  overflow-y: scroll;
  clear: both;
  padding-top: 0;
  padding-bottom: 10px;
}

.panel ::-webkit-scrollbar-track {
  display: none;
}

.panel .content.scrollable h3,
.panel .content.scrollable h2,
.panel .content.scrollable h1 {
  margin: 0;
}

.panel ::-webkit-scrollbar-thumb {
  background: white;
  width: 8px;
}

@media only screen and (max-width: 600px) {
  .App .panel {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 600px) {
  .App .panel {
    margin: 0 auto;
  }
}

.distributions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.distributions .bars, .distribution .text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2px 0;
  font-size: .8em;
}

.distributions .bars .barBackground {
  max-width: 80px;
  height: 15px;
  background-color: var(--stat-bar-background);
  display: flex;
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  flex-grow: 1;
  border-radius: 2px;
}

.distributions .bars .bar {
  height: 15px;
  background-color: var(--stat-bar-fill);
  border-radius: 2px;;
}

.distributions .text .stat {
  text-align: left;
}

.App .panel .content {
  padding: 10px 0px;
}

.App .panel .x {
  width: 44px;
  height: 44px;
  margin-right: -10px;
  margin-top: -10px;
  float: right;
  background-image: url(./skin/assets/btnX.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.App .gameScreen .bottom {
  display: grid;
}

.keyboardContainer {
  grid-column: 1;
  grid-row: 1;
}

.resultsContainer {
  grid-column: 1;
  grid-row: 1;
}

.gameScreen .hint {
  font-weight: bold;
  margin: 5px 0;
}

#helpScreen p.legal {
  font-size: 0.8em;
  margin: 3px auto;
}