@import url('./css/chillax.css');

.title {
  height: 26px;
  margin: 5px;
}

body {
  background: url(./assets/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0.8em;
}

body {
  background-color: black;
  color: white;
  font-family: var(--main-font);
}

:root {
  --cell-color: #FFFFFF;
  --cell-color-missed: #94969A;

  --board-background-storke: #5E6066;

  --key-normal-color: #020614;
  --key-missed-color: #94969A;
  --key-found-color: #FFFFFF;

  --button-bg-color: #FFFFFF;
  --button-corner-radius: 5px;
  --button-font-color: #020614;

  --green-color: #47DA9C;
  --yellow-color: #F7CF41;
  --missing-color: #94969A;

  --panel-bg-color: #01030A;
  --panel-border-color: #5E6066;
  --panel-border-opacity: 70%;

  --main-font: 'Space Mono', monospace;

  --stat-bar-background: #5E6066;
  --stat-bar-fill: #FFFFFF;

  --banner-font-color: #FFFFFF;
  --banner-background-info: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(98,4,131,1) 100%);
  --banner-background-reveal: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,41,247,1) 80%);
  --banner-font-size-info: 16px;
  --banner-font-size-reveal: 40px;
  --banner-height: 44px;
}

#helpBtn {
  background: url(./assets/btnHelp.png);
  background-size: contain;
  background-repeat: no-repeat;
}

#helpBtn.active {
  background: url(./assets/btnHelp_active.png);
  background-size: contain;
  background-repeat: no-repeat;
}

#settingsBtn {
  background: url(./assets/btnMenu.png);
  background-size: contain;
  background-repeat: no-repeat;

}

#settingsBtn.active {
  background: url(./assets/btnMenu_active.png);
  background-size: contain;
  background-repeat: no-repeat;
}

#walletBtn {
  background: url(./assets/btnWallet.png);
  background-size: contain;
  background-repeat: no-repeat;
}

#walletBtn.active {
  background: url(./assets/btnWallet_active.png);
  background-size: contain;
  background-repeat: no-repeat;
}

#statsBtn {
  background: url(./assets/btnStats.png);
  background-size: contain;
  background-repeat: no-repeat;
}

#statsBtn.active {
  background: url(./assets/btnStats_active.png);
  background-size: contain;
  background-repeat: no-repeat;
}

button {
  background-color: var(--button-bg-color);
  border: none;
  border-radius: var(--button-corner-radius);

  font-family: var(--main-font);
  color: var(--button-font-color);
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 60px;
  outline: none;
  margin: 20px;
  text-transform: uppercase;
}

button:disabled {
  cursor: default;
}

.green {
  color: var(--green-color);
}

.yellow {
  color: var(--yellow-color);
}

.missed {
  color: var(--missing-color);
}

.grid > div > div.letterBull {
  background-size: cover;
  background-image: url(./assets/cellGreen.png);
  color: var(--cell-color);
}

.grid > div > div.letterCow {
  background-size: cover;
  background-image: url(./assets/cellYellow.png);
  color: var(--cell-color)
}

.grid > div > div.letterNone {
  background-size: cover;
  background-image: url(./assets/cellMissing.png);
  color: var(--cell-color-missed);
}

.grid > div > div.letterEmpty {
  background-size: cover;
  background-image: url(./assets/cellEmpty.png);
  color: var(--cell-color)
}

#playingScreen .grid {
  background-color: #BBBBBB44;
  border-radius: 5px;
  border: #5E6066 solid 1px;
}

.keyboard > div > div.letterBull {
  background-size: cover;
  background-image: url(./assets/keyGreen.png);
  color: var(--key-found-color)
}

.keyboard > div > div.letterCow {
  background-size: cover;
  background-image: url(./assets/keyYellow.png);
  color: var(--key-found-color)
}

.keyboard > div > div.letterNone {
  background-size: cover;
  background-image: url(./assets/keyMissing.png);
  color: var(--key-normal-color)
}

.keyboard > div > div.letterEmpty {
  background-size: cover;
  background-image: url(./assets/keyNormal.png);
  color: var(--key-normal-color)
}

.keyboard > div > div.enter {
  background-color: #FFFFFF00;
  border: gray solid 1px;
  padding: 0 3px;
}

.keyboard > div > div.delete {

  padding: 0 3px;
  background-color: #FFFFFF00;
  border: gray solid 1px;
}


.claim-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 5px;
  background: #47da9c;
  z-index: 5;
  transition: width 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: var(--button-corner-radius);
}
